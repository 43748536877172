.text-error {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--danger400);
}
@media (max-width: 768px) {
    .text-error {
        font-size: 12px;
        line-height: 16px;
    }
}

@media (max-width: 480px) {
    .text-error {
        font-size: 12px;
        line-height: 16px;
    }

}