.breadcrumb-admin {
    margin-bottom: 16px;
    .bread-item {
        font-family: 'Nunito', sans-serif;
        font-size: 12px;
        .bread-link {
            text-decoration: none;
            color: var(--primary400);
        }
        &.active {
            color: var(--neutral400);
        }
        &::before {
            content: '';
        }
    }
}