.quick-content {
    .top {
        .tx-header5 {
            display: inline-block;
            border: 0px;
        }
    }
    .height36 {
        height: 36px;
    }
    .btn-position {
        padding-left: 32px;
        .setPosition {
            color: var(--neutral200);
            transition: all 0.2s ease-in-out;
            &:hover {
                color: var(--neutral500);
                transition: all 0.2s ease-in-out;
                cursor: pointer;
            }
        }
    }
    .accordion {
        .accordion-item {
            border: none;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.accordion-subtopic {
                &:hover {
                    cursor: default !important;
                }
            }
            .accordion-header {
                padding: 0;
                border: 1px solid var(--secondary400);
                position: relative;
                &.accordion-subtopic {
                    &:hover {
                        cursor: default !important;
                    }
                }
                .accordion-button {
                    padding: 16px 32px;    
                    box-shadow: none;
                    background-color: var(--secondary200);
                    &::after {
                        background-image: url('../../../../assets/images/ArrowDown.svg');
                        background-position: center;
                        width: 15px;
                        height: 15px;
                        background-size: contain;
                        transition: all ease-in-out .2s;
                    }
                    .icon-header {
                        color: var(--neutral500);
                        background-color: var(--secondary300);
                        border-radius: 50%;
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 16px;
                    }
                    &.collapsed { 
                        padding: 16px 32px;
                        box-shadow: none;
                        background-color: var(--neutral50);
                        .icon-header {
                            color: var(--neutral500);
                            background-color: var(--secondary300);
                            border-radius: 50%;
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 16px;
                        }
                    }
                    &.accordion-subtopic {
                        &:hover {
                            cursor: default !important;
                        }
                    }
                }
                &.hide-arrow {
                    .accordion-button {
                        &::after {
                            background-image: none;
                        }

                    }
                    .btn-more {
                        right: 1.8%;
                    }
                }
                .title-accordion {
                    z-index: 5;
                    position: absolute;
                    left: 6rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .btn-more {
                    position: absolute;
                    right: 5rem;
                    top: 50%;
                    transform: translateY(-60%);
                    z-index: 4;
                    display: flex;
                    .btnToggleMore {
                        border: none;
                        background-color: transparent;
                        padding-left: 8px;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            .accordion-collapse {
                background-color: var(--neutral50);
                border-left: 1px solid var(--secondary400);
                margin-left: 20px;
                .accordion-body {
                    padding: 8px 0 8px 20px;
                }
            }
        }
    }
}

.status-active {
    background-color: var(--info100);
    border-radius: 4px;
    padding: 2px 6px;
    color: var(--success600);
    font-weight: 600;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    &.ishover {
        &:hover {
            color: var(--success600) !important;
            border: 1px solid var(--success600) !important;
        }
        &:focus {
            color: var(--success600) !important;
            border: 1px solid var(--success600) !important;
        }

    }
}
.status-inactive {
    background-color: var(--danger100);
    border-radius: 4px;
    padding: 2px 6px;
    color: var(--danger500);
    font-weight: 600;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    &.ishover {
        &:hover {
            color: var(--danger500) !important;
            border: 1px solid var(--danger500) !important;
        }
        &:focus {
            color: var(--danger500) !important;
            border: 1px solid var(--danger500) !important;
        }        
    }
} 
.btn-big {
    border-radius: 8px;
    height: 72px;
    border: 1px dashed var(--neutral300);
    background-color: var(--neutral50);
    transition: all 0.5s ease-in-out;
    &:hover {
      background-color: var(--primary100);
      span {
        color: var(--neutral400);
      }
    }
}

@media (max-width: 768px) {
}
@media (max-width: 480px) {
    .quick-content {
        .top {
            .tx-header5 {
                font-size: 16px;
            }
            .icon {
                font-size: 20px;
            }
        }
        .height36 {
            height: 26px;
        }
        .btn-position {
            padding-left: 32px;
            .setPosition {
                color: var(--neutral200);
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: var(--neutral500);
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        height: 62px;
                        // padding: 8px 16px 8px 8px;      
                        padding: 8px 16px;    
                        .icon-header {
                            display: none;
                        }
                        &.collapsed {
                            // padding: 8px 16px 8px 8px;      
                            padding: 8px 16px;    
                            .icon-header {
                                display: none;
                            }
                        }
                    }
                    .title-accordion {
                        left: 1rem;
                        .tx-body-po {
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 110px;
                        }
                    }
                    .btn-more {
                        right: 3rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    .btn-big {
        padding: 16px;
        .tx-body {
            font-size: 14px;
            .icon {
                font-size: 18px;
            }
        }
    }
}

.table-assessment {
    font-family: 'Nunito', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 4px;
    thead, tfoot {
        tr {
            th {
                padding: 16px 8px;
                font-weight: 700;
                font-size: 14px;
                background-color: var(--secondary200);
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 0.5px solid var(--secondary500);
            td {
                padding-left: 16px;
                padding-right: 16px;
                height: 52px;
                background-color: var(--neutral50);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 140px;
            }
        }
    }
} 
@media (max-width: 480px) {
    .table-assessment {
        margin-bottom: 2px;
        thead, tfoot {
            tr {
                th {
                    padding: 12px 6px;
                    font-size: 12px;
                }
            }
        }
    
        tbody {
            tr {
                td {
                    padding-left: 8px;
                    padding-right: 8px;
                    height: 46px;
                    font-size: 12px;
                    line-height: 16px;
                    max-width: 100px;
                }
            }
        }
    } 
}