.DropdownSelect {
    box-shadow: none;
    .form-label {
        margin-bottom: 4px;
    }
    .dropdown-toggle {
        position: relative;
        z-index: 1;
        margin-bottom: 0px;
        border: 1px solid var(--neutral100);
        background-color: var(--neutral50);
        color: var(--neutral500);
        padding: 8px 16px;
        font-size: 'Nunito', sans-serif;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:focus {
            box-shadow: none;
            color: var(--neutral500);
            border: 1px solid var(--primary500);
        }
        &:active {
            box-shadow: none;
            color: var(--neutral500);
            border: 1px solid var(--primary500);
        }
    }
    .dropdown-menu {
        position: relative;
        z-index: 9999;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
        border: none;
        background-color: var(--neutral50);
        padding: 8px;
        min-width:110px;
        .dropdown-item {
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            padding: 8px;
            line-height: 24px;
            color: var(--neutral400);
            border-radius: 4px;
            box-sizing: border-box;
            background-color: var(--neutral50);
            border: 1px solid var(--neutral50);
            &:hover {
                background-color: var(--secondary200);
                border: 1px solid var(--secondary500);
                color: var(--neutral500);
            }
            &:focus {
                background-color: var(--secondary500);
            }
        }
        .dropdown-divider {
            margin-top: 2px;
            margin-bottom: 2px;
            border-color: var(--secondary500);
        }
    }
    &.isSmall {
        .form-label {
           font-size: 12px;
        }
        .dropdown-toggle {
            font-size: 14px;
            line-height: 20px;
        }
        .dropdown-menu {
            .dropdown-item {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &.isExSmall {
        .form-label {
           font-size: 12px;
        }
        .dropdown-toggle {
            font-size: 12px;
            line-height: 16px;
        }
        .dropdown-menu {
            .dropdown-item {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        &.isTaSmall {
            .form-label {
               font-size: 12px;
            }
            .dropdown-toggle {
                font-size: 14px;
                line-height: 20px;
            }
            .dropdown-menu {
                .dropdown-item {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isTaExSmall {
            .form-label {
               font-size: 12px;
            }
            .dropdown-toggle {
                font-size: 12px;
                line-height: 16px;
            }
            .dropdown-menu {
                .dropdown-item {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        &.isMoSmall {
            .form-label {
               font-size: 12px;
            }
            .dropdown-toggle {
                font-size: 14px;
                line-height: 20px;
            }
            .dropdown-menu {
                .dropdown-item {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isMoExSmall {
            .form-label {
               font-size: 12px;
            }
            .dropdown-toggle {
                font-size: 12px;
                line-height: 16px;
            }
            .dropdown-menu {
                .dropdown-item {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}