.navbar-preview {
    z-index: 2;
    height: 64px;
    padding-right: 48px;
    padding-left: 48px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    .navbar-brand {
        padding: 0;
        margin: 0;
        padding-right: 16px;
        margin-right: 16px;
        border-right: 2px solid var(--secondary200);
    }
}