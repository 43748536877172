.formFormik {
    .label-checkbox {
        margin-left: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    .form-label {
        margin-bottom: 4px;
    }
    .form-control, 
    .form-select {
        border: 1px solid var(--neutral100);
        background-color: var(--neutral50);
        padding: 8px 16px;
        color: var(--neutral500);
        -webkit-text-fill-color: var(--neutral500);
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        &:disabled {
            -webkit-text-fill-color: var(--neutral500);
        }
        &::placeholder {
            font-family: 'Nunito', sans-serif;
            color: var(--neutral200);
            -webkit-text-fill-color: var(--neutral200);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        &:focus {
            box-shadow: none;
            border: 1px solid var(--primary500);
        }
        &.error {
            border: 1px solid var(--danger500);
        }
    }
    .pdf-box {
        position: relative; 
        .form-inputfile {
            opacity: 0;
            position: relative;
            z-index: 5;
        }
        .inputfilebox-shadow {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            cursor: pointer;
        }
        .btn-delete-pdf {
            color: var(--neutral200);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            z-index: 6;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .frame-imagebox {
        background-color: var(--neutral50);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed var(--neutral100);
        border-radius: 14px;
        position: relative;
        width: 100%;
        height: 100%;
        position: relative;
        .btn-delete-image {
            color: var(--neutral200);
            position: absolute;
            top: 5px;
            right: 5px;
            &:hover {
                cursor: pointer;
            }
        }
        .image-preview {
            width: 100%;
            height: 100%;
            padding: 5px;
            border-radius: 14px;
            object-fit: contain;
        }
        .imagebox {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            &:hover {
                cursor: pointer;
            }
        }
        &.error {
            border-color: var(--danger500);
        }
    }
    &.isShadow {
        .form-control, 
        .form-select {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        }
    }
    &.isSmall {
        .form-label {
           font-size: 12px;
        }
        .form-control, 
        .form-select {
            font-size: 14px;
            line-height: 20px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &.isExSmall {
        .form-label {
           font-size: 12px;
        }
        .form-control, 
        .form-select {
            font-size: 12px;
            line-height: 16px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        &.isTaSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control, 
            .form-select {
                font-size: 14px;
                line-height: 20px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isTaExSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control, 
            .form-select {
                font-size: 12px;
                line-height: 16px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        &.isMoSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control, 
            .form-select {
                font-size: 14px;
                line-height: 20px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isMoExSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control, 
            .form-select {
                font-size: 12px;
                line-height: 16px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
.hideArrow::-webkit-outer-spin-button,
.hideArrow::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
font-weight: 600;
color: var(--neutral200)
}

/* Firefox */
.hideArrow[type=number] {
-moz-appearance: textfield;
font-weight: 600;
color: var(--neutral200)
}
@media (max-width: 480px) {
    .hideArrow[type=number] {
        padding: 4px !important;
        width: 30px !important;
        }
}