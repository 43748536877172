.formPublicInfoEdit {
    min-height: 100vh;
    .height40 {
        height: 40px;
    }
    .height48 {
        height: 48px;
    }
    .divider {
        margin-top: 54px;
        margin-bottom: 54px;
        height: 1px;
        width: 100%;
        color: var(--secondary200);
        background-color: var(--secondary200);
    }
    .btnCreateForm {
        width: 220px;
        margin-left: 16px;
    }
    @media (min-width: 769px) {
        .desc {
            width: 50%;
        }
    }
}

@media (max-width: 768px) {
    .formPublicInfoEdit {
        .height40 {
            height: 24px;
        }
        .height48 {
            height: 32px;
        }
        .divider {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}
@media (max-width: 480px) {
    .formPublicInfoEdit {
        .height40 {
            height: 24px;
        }
        .height48 {
            height: 24px;
        }
        .tx-header5 {
            font-size: 16px;
            line-height: 24px;
        }
        .label-left {
            margin-bottom: 16px;
        }    
        .mb4 {
            margin-bottom: 4px;
        }    
        .btnMoBlock {
            width: 100%;
            margin-bottom: 8px;
        }
        .btnCreateForm {
            width: 100%;
            margin-left: 0px;
        }
    }
}