.navbar-admin {
    height: 82px;
    padding-right: 48px;
    padding-left: 48px;
    border-bottom: 2px solid var(--secondary200);
    .navbar-brand {
        .img-brand {
            width: 86px;
            height: 32px;
        }
        .img-brand2 {
            width: 32px;
            height: 32px;
        }
    }
    .navbar-toggler {
        padding: 0;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
    .nav-item {
        margin: 2px 20px;
        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }
        .nav-link {
            color: var(--neutral300);
            padding: 2px;
            &.active {
                color: var(--neutral500);
                border: 0px;
                border-bottom: 2px solid;
                border-image: linear-gradient(90deg, white 30%, var(--primary500) 30%, var(--primary500) 70%, white 70%, white 100%);
                border-image-slice: 1;
            }
        }
        .dropdown-item {
            color: var(--neutral300);
            &:active {
                color: var(--neutral50);
                background-color: var(--primary500);
            }
            &:focus {
                color: var(--neutral50);
                background-color: var(--primary500);
            }
        }
    }
    .avatar-account {
        width: 32px;
        height: 32px;
        margin-left: 16px;
    }
}
.dropdown-avatar {
    .btn-avatar {
        border: none;
        background-color: transparent;
        padding-left: 8px;
        box-shadow: none;
        &:active {
            box-shadow: none;
        }
        &:focus {
            box-shadow: none;
        }
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
        border: none;
        background-color: var(--neutral50);
        padding: 8px;
        .dropdown-item {
            font-family: 'Nunito', sans-serif;
            font-size: 14px;
            line-height: 20px;
            color: var(--neutral400);
            border-radius: 4px;
            box-sizing: border-box;
            background-color: var(--neutral50);
            border: 1px solid var(--neutral50);
            &:hover {
                background-color: var(--secondary200);
                border: 1px solid var(--secondary500);
                color: var(--neutral500);
            }
            &:focus {
                background-color: var(--secondary500);
            }
        }
    }
}

@media (max-width: 768px) {
    .navbar-admin {
        padding-right: 48px;
        padding-left: 48px;
        .offcanvas-header {
            margin: 2px 14px;
            .btn-close {
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &:active {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .offcanvas-body {
            .nav-item {
                margin: 2px 20px;
                &:first-child {
                    margin-left: 20px;
                }
                &:last-child {
                    margin-right: 20px;
                }
                .nav-link {
                    display: inline-block;
                }
            }
            .navbar-right {
                justify-content: flex-end;
                margin: 2px 20px;
            }
        }
    }
}
@media (max-width: 480px) {
    .navbar-admin {
        height: 66px;
        padding-right: 24px;
        padding-left: 24px;
        .avatar-account {
            margin-left: 25px;
        }
    }
}