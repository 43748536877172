.Toastify {
    .toast-admin {
        .Toastify__toast {
            background-color: var(--neutral50);
            box-sizing: border-box;
            border-radius: 8px;
            padding: 12px;
            &.Toastify__toast--success {
                box-shadow: 0px 2px 16px rgba(142, 238, 209, 0.2);
                border: 1px solid var(--info500);
            }
            &.Toastify__toast--error {
                box-shadow: 0px 2px 16px rgba(220, 53, 69, 0.2);
                border: 1px solid var(--danger500);
            }
            &.Toastify__toast--warning  {
                box-shadow: 0px 2px 16px rgba(142, 207, 238, 0.2);
                border: 1px solid var(--success500);
            }
        }
    }
}