.input-base64 {
  cursor: pointer;
  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .img-preview {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
