.inputSearch {
    position: relative;
    &.has-search {
        .form-control {
            padding-left: 56px;
        }
        &.isSmall {
            .form-control {
                padding-left: 52px;
            }
        }
        &.isExSmall {
            .form-control {
                padding-left: 48px;
            }
        }
        @media (max-width: 768px) {
            &.isTaSmall {
                .form-control {
                    padding-left: 52px;
                }
            }
            &.isTaExSmall {
                .form-control {
                    padding-left: 48px;
                }
            }
        }
        @media (max-width: 480px) {
            &.isMoSmall {
                .form-control {
                    padding-left: 52px;
                }
            }
            &.isMoExSmall {
                .form-control {
                    padding-left: 48px;
                }
            }
        }
    }
    .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: flex;
        width: 56px;
        height: 100%;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        .icon-search {
            color: var(--neutral200);
            font-size: 20px;
        }
    }
    .form-control {
        border-radius: 8px;
        border: 1px solid var(--neutral100);
        background-color: var(--neutral50);
        padding: 16px;
        color: var(--neutral500);
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &::placeholder {
            font-family: 'Nunito', sans-serif;
            color: var(--neutral200);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        &:focus {
            box-shadow: none;
            border: 1px solid var(--primary500);
        }
    }
    &.isShadow {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    }
    &.isSmall {
        .form-control-feedback {
            .icon-search {
                font-size: 18px;
            }
        }
        .form-control {
            font-size: 14px;
            line-height: 20px;
            padding: 14px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &.isExSmall {
        .form-control-feedback {
            .icon-search {
                font-size: 16px;
            }
        }
        .form-control {
            font-size: 12px;
            line-height: 16px;
            padding: 12px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        &.isTaSmall {
            .form-control-feedback {
                .icon-search {
                    font-size: 18px;
                }
            }
            .form-control {
                font-size: 14px;
                line-height: 20px;
                padding: 7px 14px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isTaExSmall {
            .form-control-feedback {
                .icon-search {
                    font-size: 16px;
                }
            }
            .form-control {
                font-size: 12px;
                line-height: 16px;
                padding: 6px 12px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        &.isMoSmall {
            .form-control-feedback {
                .icon-search {
                    font-size: 18px;
                }
            }
            .form-control {
                font-size: 14px;
                line-height: 20px;
                padding: 7px 14px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isMoExSmall {
            .form-control-feedback {
                .icon-search {
                    font-size: 16px;
                }
            }
            .form-control {
                font-size: 12px;
                line-height: 16px;
                padding: 6px 12px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}