.bg-white500 {background-color: var(--white500) !important;}

.bg-neutral1000 {background-color: var(--neutral1000) !important;}
.bg-neutral900 {background-color: var(--neutral900) !important;}
.bg-neutral800 {background-color: var(--neutral800) !important;}
.bg-neutral700 {background-color: var(--neutral700) !important;}
.bg-neutral600 {background-color: var(--neutral600) !important;}
.bg-neutral500 {background-color: var(--neutral500) !important;}
.bg-neutral400 {background-color: var(--neutral400) !important;}
.bg-neutral300 {background-color: var(--neutral300) !important;}
.bg-neutral200 {background-color: var(--neutral200) !important;}
.bg-neutral100 {background-color: var(--neutral100) !important;}
.bg-neutral50 {background-color: var(--neutral50) !important;}

.bg-primary500 {background-color: var(--primary500) !important;}
.bg-primary400 {background-color: var(--primary400) !important;}
.bg-primary300 {background-color: var(--primary300) !important;}
.bg-primary200 {background-color: var(--primary200) !important;}
.bg-primary100 {background-color: var(--primary100) !important;}

.bg-success600 {background-color: var(--success600) !important;}
.bg-success500 {background-color: var(--success500) !important;}
.bg-success400 {background-color: var(--success400) !important;}
.bg-success300 {background-color: var(--success300) !important;}
.bg-success200 {background-color: var(--success200) !important;}
.bg-success100 {background-color: var(--success100) !important;}

.bg-danger500 {background-color: var(--danger500) !important;}
.bg-danger400 {background-color: var(--danger400) !important;}
.bg-danger300 {background-color: var(--danger300) !important;}
.bg-danger200 {background-color: var(--danger200) !important;}
.bg-danger100 {background-color: var(--danger100) !important;}

.bg-warning500 {background-color: var(--warning500) !important;}
.bg-warning400 {background-color: var(--warning400) !important;}
.bg-warning300 {background-color: var(--warning300) !important;}
.bg-warning200 {background-color: var(--warning200) !important;}
.bg-warning100 {background-color: var(--warning100) !important;}

.bg-info500 {background-color: var(--info500) !important;}
.bg-info400 {background-color: var(--info400) !important;}
.bg-info300 {background-color: var(--info300) !important;}
.bg-info200 {background-color: var(--info200) !important;}
.bg-info100 {background-color: var(--info100) !important;}

.bg-secondary500 {background-color: var(--secondary500) !important;}
.bg-secondary400 {background-color: var(--secondary400) !important;}
.bg-secondary300 {background-color: var(--secondary300) !important;}
.bg-secondary200 {background-color: var(--secondary200) !important;}
.bg-secondary100 {background-color: var(--secondary100) !important;}