.top-table {
    background-color: var(--secondary300);
    height: 72px;
    border-radius: 8px;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 24px;
}
.global-search {
    width: 312px;
}
.table-course {
    font-family: 'Nunito', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0px;
    thead {
        tr {
            th {
                padding: 16px 16px 8px 16px;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                background-color: var(--secondary200);
            }
            &.header-filtering {
                th {
                    padding: 4px 16px 10px 16px;
                }
            }
            &:first-child {
                th {
                    &:first-child {
                        border-top-left-radius: 8px;
                    }
                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }
        }
    }

    &.table-user {
        tbody {
            tr {
                td {
                    max-width: 320px;
                }
            }
        }
    }

    tbody {
        tr {
            border-bottom: 0.5px solid var(--secondary500);
            td {
                padding-left: 16px;
                padding-right: 16px;
                height: 52px;
                background-color: var(--neutral50);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 140px;
            }
        }
    }

    .status-active {
        background-color: var(--info100);
        border-radius: 4px;
        padding: 2px 6px;
        color: var(--success600);
        font-weight: 500;
        display: inline-block;
    }

    .status-inactive {
        background-color: var(--danger100);
        border-radius: 4px;
        padding: 2px 6px;
        color: var(--danger500);
        font-weight: 500;
        display: inline-block;
    }  
    .form-check {
        .checkbox-table {
            width: 14px;
            height: 14px;
            &:checked {
                background-color: var(--success500);
            }
        }
    }
    .datanotfound {
        padding: 16px;
    }
} 
.navigation-table {
    margin-bottom: 80px;
    background-color: var(--secondary200);
    height: 44px;
    padding-right: 16px;
    padding-left: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination-table {
        display: flex;
        align-items: center;
        .divider-nav {
            margin-left: 32px;
            margin-right: 32px;
            background-color: var(--secondary500);
            width: 1px;
            height: 28px;
        }
        .numberpage {
            background-color: var(--neutral50);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 4px;
            border: 1px solid var(--secondary500);
            font-family: 'Nunito', sans-serif;
            font-size: 14px;
            color: var(--neutral500);
            line-height: 20px;
            outline: none;
            margin-left: 16px;
        }

    }
}

@media (max-width: 768px) {
}
@media (max-width: 480px) {
    .top-table {
        height: 116px;
        padding-right: 24px;
        padding-left: 24px;
        margin-bottom: 16px;
        flex-direction: column;
        justify-content: center !important;
        &.top-optional {
            height: auto;
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .left {
            width: 100%;
            margin-bottom: 8px;
            .global-search {
                width: 100% !important;
                height: 40px;
            }
        }
        .right {
            width: 100%;
            &.right-optional {
                display: inline-block !important;
                .dropdown {
                    margin: 0 !important;
                    .dropdown-toggle {
                        width: 100% !important;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .dropdown-menu {
                        width: 100%;
                    }
                }
            }
            .btnAdd {
                width: 100%;
                height: 40px;
            }
        }
    }
    .table-course {
        tbody {
            tr {
                td {
                    max-width: 200px;
                }
            }
        }
    } 
    .navigation-table {
        justify-content: center;
        .pagination-table {
            justify-content: space-between;
            .your-page {
                font-size: 12px;
            }
            .numberpage {
                padding: 2px;
                font-size: 12px;
                margin-left: 8px;
            }
            .divider-nav {
                margin-left: 24px;
                margin-right: 24px;
                height: 24px;
            }
        }
    }
}

.formSubmitCSV {
    .btnCreateForm {
        width: 220px;
        margin-left: 16px;
    }
    @media (min-width: 769px) {
        .desc {
            width: 50%;
        }
    }
}
@media (max-width: 480px) {
    .formSubmitCSV {
        .btnMoBlock {
            width: 100%;
            margin-bottom: 8px;
        }
        .btnCreateForm {
            width: 100%;
            margin-left: 0px;
        }
    }
}