.inputText {
    box-shadow: none;
    .form-label {
        margin-bottom: 4px;
    }
    .form-control {
        border: 1px solid var(--neutral100);
        background-color: var(--neutral50);
        padding: 8px 16px;
        color: var(--neutral500);
        font-family: 'Nunito', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        &::placeholder {
            font-family: 'Nunito', sans-serif;
            color: var(--neutral200);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
        &:focus {
            box-shadow: none;
            border: 1px solid var(--primary500);
        }
    }
    &.isShadow {
        .form-control {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        }
    }
    &.isSmall {
        .form-label {
           font-size: 12px;
        }
        .form-control {
            font-size: 14px;
            line-height: 20px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    &.isExSmall {
        .form-label {
           font-size: 12px;
        }
        .form-control {
            font-size: 12px;
            line-height: 16px;
            &::placeholder {
                font-family: 'Nunito', sans-serif;
                color: var(--neutral200);
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        &.isTaSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control {
                font-size: 14px;
                line-height: 20px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isTaExSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control {
                font-size: 12px;
                line-height: 16px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        &.isMoSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control {
                font-size: 14px;
                line-height: 20px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        &.isMoExSmall {
            .form-label {
               font-size: 12px;
            }
            .form-control {
                font-size: 12px;
                line-height: 16px;
                &::placeholder {
                    font-family: 'Nunito', sans-serif;
                    color: var(--neutral200);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}