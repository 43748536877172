.footer-admin {
    border-top: 1px solid var(--white500);
    border-bottom: 1px solid var(--white500);
    height: 69px;
}
@media (max-width: 480px) {
    .footer-admin {
        div {
            font-size: 12px;
        }
    }
}