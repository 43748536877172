.modal-sub-topic {
  text-decoration: none;
  .scrollbar-modal {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  .scrollbar-modal::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .content-card-go-to {
    &:hover {
      background-color: var(--secondary300);
      border-radius: 4px;
    }
  }
  .text-decoration-none {
    text-decoration: none;
  }
}
