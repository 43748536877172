.selectOption {
    box-shadow: none;
    .form-label {
        margin-bottom: 4px;
    }
    .select-option {
        border: 1px solid var(--neutral100);
        padding: 8px 10px;
        color: var(--neutral200);
        font-family: 'Nunito', sans-serif;
        font-size: 12px;
        outline: none;
        line-height: 16px;
        &::placeholder {
            color: var(--neutral200);
            font-family: 'Nunito', sans-serif;
            font-size: 12px;
            line-height: 16px;
        }
        &:focus {
            box-shadow: none;
            border: 1px solid var(--primary500);
        }
        &:hover {
            cursor: pointer;
        }
    }
    &.isShadow {
        .select-option {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        }
    }
    &.noAppreance {
        .select-option {
            appearance: none;
        }
    }
    &.isSmall {
        .form-label {
           font-size: 12px;
        }
        .select-option {
            padding: 6px 8px;
            font-size: 10px;
        }
    }
    &.isExSmall {
        .form-label {
           font-size: 12px;
        }
        .select-option {
            padding: 6px 8px;
            font-size: 8px;
        }
    }
    @media (max-width: 768px) {
        &.isTaSmall {
            .form-label {
               font-size: 12px;
            }
            .select-option {
                padding: 6px 8px;
                font-size: 10px;
            }
        }
        &.isTaExSmall {
            .form-label {
               font-size: 12px;
            }
            .select-option {
                padding: 6px 8px;
                font-size: 8px;
            }
        }
    }
    @media (max-width: 480px) {
        &.isMoSmall {
            .form-label {
               font-size: 12px;
            }
            .select-option {
                padding: 6px 8px;
                font-size: 10px;
            }
        }
        &.isMoExSmall {
            .form-label {
               font-size: 12px;
            }
            .select-option {
                padding: 6px 8px;
                font-size: 8px;
            }
        }
    }
}