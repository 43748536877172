@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.text-editor {
  padding: 20px 48px;
  min-height: 90vh;
  .text-strong {
    font-weight: bold;
  }

  .more-option {
    border: 1px dashed var(--neutral200);
    span:hover {
      color: var(--neutral500);
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .new-question:hover {
    color: var(--neutral500);
  }
  .remove-pg-button {
    width: 50px;
    height: 40px;
    background-color: white;
    &:hover {
      background-color: var(--neutral150);
    }
  }
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--primary500);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 768px) {
  .text-editor {
    padding: 24px 48px;
  }
}
@media (max-width: 480px) {
  .text-editor {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}