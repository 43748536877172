.header-breadcrumbs {
    .tx-header4 {
        display: inline-block;
        border: 0px;
        border-bottom: 2px solid;
        border-image: linear-gradient(90deg, var(--primary500) 50%, white 50%);
        border-image-slice: 1;
        margin-bottom: 44px;
    }
}

@media (max-width: 480px) {
    .header-breadcrumbs {
        .tx-header4 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }
}