.dashboard-list {
    padding: 32px 48px;
    min-height: 90vh;
}

@media (max-width: 768px) {
}
@media (max-width: 480px) {
    .dashboard-list {
        padding: 16px 24px;
    }
}