@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";

.assessment-show {
  @include marginPaddingGenerator(100, ".");
  @include borderRadiusGenerator(".");

  min-height: 90vh;
  // padding-left: 48px;
  // padding-right: 48px;
  .label-success {
    border: 1px solid var(--success600);
    padding: 2px 8px 2px 8px;
    color: var(--success600);
  }
  .button-create-assessment {
    border-radius: 8px;
    padding: 32px;
    border: 1px dashed var(--neutral300);
    background-color: var(--neutral50);
    transition: all 0.5s ease-in-out;
    &:hover {
      background-color: var(--primary100);
      span {
        color: var(--neutral400);
      }
    }
  }
}

@media (max-width: 768px) {
  .assessment-show {
    padding: 24px 48px;
  }
}
@media (max-width: 480px) {
  .assessment-show {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}
