@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.code-editor {
  padding: 20px 48px;
  min-height: 90vh;
  .text-strong {
    font-weight: bold;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .code-editor {
    padding: 24px 48px;
  }
}
@media (max-width: 480px) {
  .code-editor {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}
