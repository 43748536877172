@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.multiple-choice {
  padding: 20px 48px;
  min-height: 90vh;
  .text-strong {
    font-weight: bold;
  }
  .pg-card {
    width: 55px;
    height: 40px;
  }
  .pg-abjad {
    width: 45px;
    height: 40px;
  }
  .pg-input {
    width: 100%;
  }
  .more-option {
    border: 1px dashed var(--neutral200);
    span:hover {
      color: var(--neutral500);
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .new-question:hover {
    color: var(--neutral500);
  }
  .remove-pg-button {
    background-color: white;
    &:hover {
      background-color: var(--neutral150);
    }
  }
  .pg-card {
    padding: 8px;
    border-radius: 8px;
    .btn-delete {
      color: var(--neutral300);
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .multiple-choice {
    padding: 24px 48px;
    .pg-card {
      padding: 8px;
      .btn-delete {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 480px) {
  .multiple-choice {
    padding: 20px 24px;
    .pg-card {
      padding: 0px 8px;
      .btn-delete {
        font-size: 16px;
      }
    }
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}