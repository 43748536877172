.school-info {
    padding: 20px 48px;
    min-height: 90vh;
    .nav-back {
        margin-top: 19px;
        margin-bottom: 28px;
    }  
    .navtabs-admin {
        .nav-tabs {
            border-bottom: 2px solid var(--secondary500);
            padding: 0px;
            .nav-link {
                font-family: 'Nunito', sans-serif;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: var(--neutral400);
                border-color: transparent;
                padding: 2px 2px;
                margin-right: 32px;
                &.active {
                    font-weight: 600;
                    color: var(--neutral500);
                    border-color: transparent;
                    border-bottom: 2px solid var(--primary500);
                }
            }
        }
    }
    .tab-content-admin {
        padding: 32px 0;
    }
}

@media (max-width: 768px) {
    .school-info {
        padding: 24px 48px;
        .nav-back {
            margin-top: 0px;
            margin-bottom: 24px;
        }   
        .navtabs-admin {
            .nav-tabs {
                .nav-link {
                    margin-right: 24px;
                }
            }
        }
        .tab-content-admin {
            padding: 24px 0;
        }
    }
}
@media (max-width: 480px) {
    .school-info {
        padding: 20px 24px;
        .nav-back {
            margin-top: 0px;
            margin-bottom: 28px;
            a {
                font-size: 12px;
                line-height: 16px;
            }
            .iconback {
                font-size: 16px;
            }
        }   
        .navtabs-admin {
            .nav-tabs {
                .nav-link {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 24px;
                }
            }
        }
        .tab-content-admin {
            padding: 24px 0;
        }
    }
}