.tx-display {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    color: var(--neutral500);
}
.tx-header1 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: var(--neutral500);
}
.tx-header2 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: var(--neutral500);
}
.tx-header3 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: var(--neutral500);
}
.tx-header4 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--neutral500);
}
.tx-header5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--neutral500);
}
.tx-header6 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}

.tx-body {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}
.tx-body-hl {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}
.tx-body-po {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}
.tx-body-qu {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}
.tx-small {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral500);
}
.tx-small-hl {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral500);
}
.tx-small-po {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral500);
}
.tx-small-qu {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral500);
}
.tx-ex-small {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral500);
}
.tx-ex-small-hl {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral500);
}
.tx-ex-small-po {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral500);
}
.tx-ex-small-qu {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral500);
}




h1 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: var(--neutral500);
}
h2 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: var(--neutral500);
}
h3 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: var(--neutral500);
}
h4 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: var(--neutral500);
}
h5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--neutral500);
}
h6 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral500);
}