.ReactModal__Body--open {
    // overflow-x: hidden;
    // overflow-y: auto;
    overflow: hidden !important;

    .ReactModal__Overlay {
        background-color: rgba(0, 0, 0, .3) !important;
        z-index: 1055 !important;
    }

    .ReactModal__Content {
        padding: 0 !important;
        border: none !important;
        bottom: auto !important;
        min-height: 10rem !important;
        left: 50% !important;
        right: auto !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        min-width: 15rem !important;
        width: 80% !important;
        max-width: 30rem !important;
        background-color: none !important;
        border-radius: 16px !important;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24) !important;

        @media (max-width: 480px) {
            width: 95% !important;
        }

        .modal-content {
            border: none;

            .modal-header {
                display: block;
            }

            &.isForm {
                .modal-header {
                    padding: 24px 24px 16px 24px;
                    background-color: var(--neutral50);
                }

                .modal-body {
                    background-color: #F7F8FA;
                    padding: 22px 24px;

                    .message {
                        font-family: 'Nunito', sans-serif;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--neutral500);
                        white-space: pre-wrap;
                        overflow: visible;
                        text-overflow: none;
                        max-width: auto;
                    }

                    .formInput {
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                }

                .modal-footer {
                    padding: 16px 24px;
                    background-color: var(--neutral50);

                    .btn-confirm {
                        border-radius: 8px;
                        text-decoration: none;
                        color: var(--neutral500);
                        border: 1px solid var(--primary400);
                        background-color: var(--primary400);

                        &:hover {
                            color: var(--neutral500);
                            border: 1px solid var(--primary300);
                            background-color: var(--primary300);
                        }

                        &:active {
                            box-shadow: none;
                            color: var(--neutral500);
                            border: 1px solid var(--primary500);
                            background-color: var(--primary500);
                        }

                        &:focus {
                            box-shadow: none !important;
                            color: var(--neutral500);
                            border: 1px solid var(--neutral500);
                            background-color: var(--primary400);
                        }

                        &.disabled {
                            box-shadow: none;
                            color: var(--neutral300);
                            border: 1px solid var(--neutral300);
                            background-color: var(--neutral100);

                        }
                    }
                }
            }

            &.isNoForm {
                .modal-header {
                    padding: 16px 24px;
                    border-top-right-radius: 16px;
                    border-top-left-radius: 16px;
                    background-color: var(--danger400);

                    h5 {
                        color: var(--neutral50);
                    }
                }

                .modal-body {
                    background-color: var(--neutral50);
                    padding: 22px 24px;

                    .message {
                        font-family: 'Nunito', sans-serif;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--neutral500);
                        white-space: pre-wrap;
                        overflow: visible;
                        text-overflow: none;
                        max-width: auto;
                    }
                }

                .modal-footer {
                    background-color: var(--neutral50);

                    .btn-confirm {
                        border-radius: 8px;
                        text-decoration: none;
                        color: white;
                        border: 1px solid var(--danger400);
                        background-color: var(--danger400);

                        &:hover {
                            color: white;
                            border: 1px solid var(--danger300);
                            background-color: var(--danger300);
                        }

                        &:active {
                            box-shadow: none;
                            color: white;
                            border: 1px solid var(--danger500);
                            background-color: var(--danger500);
                        }

                        &:focus {
                            box-shadow: none !important;
                            color: white;
                            border: 1px solid var(--neutral500);
                            background-color: var(--danger400);
                        }

                        &.disabled {
                            box-shadow: none;
                            color: var(--neutral300);
                            border: 1px solid var(--neutral300);
                            background-color: var(--neutral100);

                        }
                    }
                }
            }

            &.isCaution {
                .modal-header {
                    padding: 24px 24px 16px 24px;
                    border-top-right-radius: 16px;
                    border-top-left-radius: 16px;
                    background-color: var(--success400);

                    h5 {
                        color: var(--neutral500);
                    }
                }

                .modal-body {
                    background-color: var(--neutral50);
                    padding: 22px 24px;

                    .message {
                        font-family: 'Nunito', sans-serif;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--neutral500);
                        white-space: pre-wrap;
                        overflow: visible;
                        text-overflow: none;
                        max-width: auto;
                    }
                }

                .modal-footer {
                    background-color: var(--neutral50);

                    .btn-confirm {
                        border-radius: 8px;
                        text-decoration: none;
                        color: var(--neutral500);
                        border: 1px solid var(--success400);
                        background-color: var(--success400);

                        &:hover {
                            color: white;
                            border: 1px solid var(--success300);
                            background-color: var(--success300);
                        }

                        &:active {
                            box-shadow: none;
                            color: white;
                            border: 1px solid var(--success500);
                            background-color: var(--success500);
                        }

                        &:focus {
                            box-shadow: none !important;
                            color: white;
                            border: 1px solid var(--neutral500);
                            background-color: var(--success400);
                        }

                        &.disabled {
                            box-shadow: none;
                            color: var(--neutral300);
                            border: 1px solid var(--neutral300);
                            background-color: var(--neutral100);

                        }
                    }
                }
            }
        }
    }
}

.modalcrud {
    .modal-content {
        background-color: var(--neutral50);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
        border-radius: 16px;
        overflow: hidden;
        background-color: transparent;
        border: none;

        .modal-header {
            display: block;
        }

        &.isForm {
            .modal-header {
                padding: 24px 24px 16px 24px;
                background-color: var(--neutral50);
            }

            .modal-body {
                background-color: #F7F8FA;
                padding: 22px 24px;

                .message {
                    font-family: 'Nunito', sans-serif;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--neutral500);
                    white-space: pre-wrap;
                    overflow: visible;
                    text-overflow: none;
                    max-width: auto;
                }

                .formInput {
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            .modal-footer {
                padding: 16px 24px;
                background-color: var(--neutral50);

                .btn-confirm {
                    border-radius: 8px;
                    text-decoration: none;
                    color: var(--neutral500);
                    border: 1px solid var(--primary400);
                    background-color: var(--primary400);

                    &:hover {
                        color: var(--neutral500);
                        border: 1px solid var(--primary300);
                        background-color: var(--primary300);
                    }

                    &:active {
                        box-shadow: none;
                        color: var(--neutral500);
                        border: 1px solid var(--primary500);
                        background-color: var(--primary500);
                    }

                    &:focus {
                        box-shadow: none !important;
                        color: var(--neutral500);
                        border: 1px solid var(--neutral500);
                        background-color: var(--primary400);
                    }

                    &.disabled {
                        box-shadow: none;
                        color: var(--neutral300);
                        border: 1px solid var(--neutral300);
                        background-color: var(--neutral100);

                    }
                }
            }
        }

        &.isNoForm {
            .modal-header {
                padding: 24px 24px 16px 24px;
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
                background-color: var(--danger400);

                h5 {
                    color: var(--neutral50);
                }
            }

            .modal-body {
                background-color: var(--neutral50);
                padding: 22px 24px;

                .message {
                    font-family: 'Nunito', sans-serif;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--neutral500);
                    white-space: pre-wrap;
                    overflow: visible;
                    text-overflow: none;
                    max-width: auto;
                }
            }

            .modal-footer {
                background-color: var(--neutral50);

                .btn-confirm {
                    border-radius: 8px;
                    text-decoration: none;
                    color: white;
                    border: 1px solid var(--danger400);
                    background-color: var(--danger400);

                    &:hover {
                        color: white;
                        border: 1px solid var(--danger300);
                        background-color: var(--danger300);
                    }

                    &:active {
                        box-shadow: none;
                        color: white;
                        border: 1px solid var(--danger500);
                        background-color: var(--danger500);
                    }

                    &:focus {
                        box-shadow: none !important;
                        color: white;
                        border: 1px solid var(--neutral500);
                        background-color: var(--danger400);
                    }

                    &.disabled {
                        box-shadow: none;
                        color: var(--neutral300);
                        border: 1px solid var(--neutral300);
                        background-color: var(--neutral100);

                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .modal-header {
        .tx-header5 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        .tx-small {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .modal-body {
        .message {
            .tx-body-po {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.modalCreateTeacherResources {
    padding: 24px;

    .link-card {
        margin: 12px 0px;
        background-color: var(--neutral50) !important;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--neutral100) !important;

        .card-text {
            margin-left: 16px;
        }
    }

    .no-decoration {
        text-decoration: none;
        color: var(--neutral500) !important;
    }
}