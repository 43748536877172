.react-tooltips {
    z-index: 9999 !important;
    border-radius: 8px !important;
    padding: 16px !important;
    color: var(--neutral50) !important;
    border: 1px solid var(--neutral700) !important;
    background-color: rgba(21, 28, 38, 0.8) !important;
    max-width: 300px !important;
}
.tooltips-hover {
    &:hover {
        cursor: pointer;
    }
}