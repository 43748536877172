@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";

.assessment-text-editor {
  @include marginPaddingGenerator(100, ".");
  @include borderRadiusGenerator(".");

  min-height: 90vh;
}

@media (max-width: 768px) {
  .assessment-text-editor {
    padding: 24px 48px;
  }
}
@media (max-width: 480px) {
  .assessment-text-editor {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}
