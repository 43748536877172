.brd-white500 {border-color: var(--white500) !important;}

.brd-neutral1000 {border-color: var(--neutral1000) !important;}
.brd-neutral900 {border-color: var(--neutral900) !important;}
.brd-neutral800 {border-color: var(--neutral800) !important;}
.brd-neutral700 {border-color: var(--neutral700) !important;}
.brd-neutral600 {border-color: var(--neutral600) !important;}
.brd-neutral500 {border-color: var(--neutral500) !important;}
.brd-neutral400 {border-color: var(--neutral400) !important;}
.brd-neutral300 {border-color: var(--neutral300) !important;}
.brd-neutral200 {border-color: var(--neutral200) !important;}
.brd-neutral100 {border-color: var(--neutral100) !important;}
.brd-neutral50 {border-color: var(--neutral50) !important;}

.brd-primary500 {border-color: var(--primary500) !important;}
.brd-primary400 {border-color: var(--primary400) !important;}
.brd-primary300 {border-color: var(--primary300) !important;}
.brd-primary200 {border-color: var(--primary200) !important;}
.brd-primary100 {border-color: var(--primary100) !important;}

.brd-success500 {border-color: var(--success500) !important;}
.brd-success400 {border-color: var(--success400) !important;}
.brd-success300 {border-color: var(--success300) !important;}
.brd-success200 {border-color: var(--success200) !important;}
.brd-success100 {border-color: var(--success100) !important;}

.brd-danger500 {border-color: var(--danger500) !important;}
.brd-danger400 {border-color: var(--danger400) !important;}
.brd-danger300 {border-color: var(--danger300) !important;}
.brd-danger200 {border-color: var(--danger200) !important;}
.brd-danger100 {border-color: var(--danger100) !important;}

.brd-warning500 {border-color: var(--warning500) !important;}
.brd-warning400 {border-color: var(--warning400) !important;}
.brd-warning300 {border-color: var(--warning300) !important;}
.brd-warning200 {border-color: var(--warning200) !important;}
.brd-warning100 {border-color: var(--warning100) !important;}

.brd-info500 {border-color: var(--info500) !important;}
.brd-info400 {border-color: var(--info400) !important;}
.brd-info300 {border-color: var(--info300) !important;}
.brd-info200 {border-color: var(--info200) !important;}
.brd-info100 {border-color: var(--info100) !important;}

.brd-secondary500 {border-color: var(--secondary500) !important;}
.brd-secondary400 {border-color: var(--secondary400) !important;}
.brd-secondary300 {border-color: var(--secondary300) !important;}
.brd-secondary200 {border-color: var(--secondary200) !important;}
.brd-secondary100 {border-color: var(--secondary100) !important;}