.white500 {color: var(--white500) !important;}

.neutral1000 {color: var(--neutral1000) !important;}
.neutral900 {color: var(--neutral900) !important;}
.neutral800 {color: var(--neutral800) !important;}
.neutral700 {color: var(--neutral700) !important;}
.neutral600 {color: var(--neutral600) !important;}
.neutral500 {color: var(--neutral500) !important;}
.neutral400 {color: var(--neutral400) !important;}
.neutral300 {color: var(--neutral300) !important;}
.neutral200 {color: var(--neutral200) !important;}
.neutral100 {color: var(--neutral100) !important;}
.neutral50 {color: var(--neutral50) !important;}

.primary500 {color: var(--primary500) !important;}
.primary400 {color: var(--primary400) !important;}
.primary300 {color: var(--primary300) !important;}
.primary200 {color: var(--primary200) !important;}
.primary100 {color: var(--primary100) !important;}

.success600 {color: var(--success600) !important;}
.success500 {color: var(--success500) !important;}
.success400 {color: var(--success400) !important;}
.success300 {color: var(--success300) !important;}
.success200 {color: var(--success200) !important;}
.success100 {color: var(--success100) !important;}

.danger500 {color: var(--danger500) !important;}
.danger400 {color: var(--danger400) !important;}
.danger300 {color: var(--danger300) !important;}
.danger200 {color: var(--danger200) !important;}
.danger100 {color: var(--danger100) !important;}

.warning500 {color: var(--warning500) !important;}
.warning400 {color: var(--warning400) !important;}
.warning300 {color: var(--warning300) !important;}
.warning200 {color: var(--warning200) !important;}
.warning100 {color: var(--warning100) !important;}

.info500 {color: var(--info500) !important;}
.info400 {color: var(--info400) !important;}
.info300 {color: var(--info300) !important;}
.info200 {color: var(--info200) !important;}
.info100 {color: var(--info100) !important;}

.secondary500 {color: var(--secondary500) !important;}
.secondary400 {color: var(--secondary400) !important;}
.secondary300 {color: var(--secondary300) !important;}
.secondary200 {color: var(--secondary200) !important;}
.secondary100 {color: var(--secondary100) !important;}