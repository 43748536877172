@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.match-pairs {
  padding: 20px 48px;
  min-height: 90vh;

  .cursor-pointer {
    cursor: pointer;
  }
  .new-question:hover {
    color: var(--neutral500);
  }
  .w-full {
    width: 100%;
  }
  .more-option {
    border: 1px dashed var(--neutral200);
    span:hover {
      color: var(--neutral500);
    }
  }
  .abjad {
    width: 50px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--neutral100);
    background-color: #fff;
    margin-right: 16px;
  }
  .delete {
    width: 50px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--neutral100);
    margin-left: 16px;
  }

  .img-button {
    height: 42px;
  }

  .close-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: var(--neutral100);
    border-radius: 4px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .match-pairs {
    padding: 24px 48px;
    .img-button {
      height: 38px;
    }
    .delete {
      height: 38px;
    }
  }
}
@media (max-width: 480px) {
  .match-pairs {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
    .img-button {
      height: 34px;
    }

    .delete {
      height: 34px;
    }
  }
}
