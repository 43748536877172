@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.fill-in-the-blank {
  padding: 20px 48px;
  min-height: 90vh;
  .text-strong {
    font-weight: bold;
  }
  .pg-card {
    width: 50px;
    height: 40px;
  }
  .pg-input {
    width: 100%;
  }
  .more-option {
    border: 1px dashed var(--neutral200);
    span:hover {
      color: var(--neutral500);
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .new-question:hover {
    color: var(--neutral500);
  }
  .remove-pg-button {
    width: 50px;
    height: 40px;
    background-color: white;
    &:hover {
      background-color: var(--neutral150);
    }
  }
  .inp-group-radius {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 768px) {
  .fill-in-the-blank {
    padding: 20px 8px;
  }
}
@media (max-width: 480px) {
  .course-list {
    padding: 16px 24px;
  }
}