.page-login {
    height: 100vh;
    width: 100%;
    .header {
        padding: 40px;
    }
    .footer {
        padding: 23px;
        border-top: 1px solid var(--white500);
    }
    .top {
        margin-bottom: 40px;
    }
    .height18 {
        height: 18px;
    }
    .height20 {
        height: 20px;
    }
    .height100 {
        height: 100px;
    }
}

@media (max-width: 480px) {
    .page-login {
        .footer {
            font-size: 12px;
        }
    }
}