@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";
@include marginPaddingGenerator(100, ".");
@include borderRadiusGenerator(".");
.sub-topic-detatil-show {
  min-height: 90vh;
  .height24 {
    height: 24px;
  }
  .w-full {
    width: 100% !important;
  }
  .card-container {
    box-shadow: 0 0 15px rgb(226, 224, 224);
    border-radius: 8px;
    padding: 0;
    .card-content {
      padding: 16px;
      ul,
      ol {
        margin-left: 22px;
      }
      img {
        border-radius: 8px;
      }
    }
    .card-footer {
      padding: 12px;
      color: var(--neutral300);
      border: none;
    }
  }
  .select-order {
    border: none;
    box-shadow: 0 0 10px rgb(194, 193, 193);
    border-radius: 2px;
    padding: 3px;
  }
  .action-icon {
    border: none;
    box-shadow: 0 0 5px rgb(194, 193, 193);
    border-radius: 2px;
    padding: 4px;
    cursor: pointer;
    background-color: white;
    &:hover {
      background-color: var(--neutral100);
    }
  }
  .btn-preview {
    box-shadow: 0 0 1px rgb(194, 193, 193);
    &:hover {
      background-color: var(--secondary200) !important;
    }
  }
  .fill-in-the-blank {
    &-input {
      color: var(--neutral500);
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  .table-content {
    border-bottom: 1px solid var(--secondary500);
  }
  .more-option {
    border-radius: 8px;
    padding: 32px;
    border: 1px dashed var(--neutral300);
    background-color: var(--neutral50);
    transition: all 0.5s ease-in-out;
    &:hover {
      background-color: var(--primary100);
      span {
        color: var(--neutral400);
      }
    }
  }
  .radio-custom {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* IMAGE STYLES */
  .radio-custom + .custom {
    cursor: pointer;
  }
  /* CHECKED STYLES */
  .radio-custom:checked + .custom {
    outline: 3.5px solid var(--primary500);
  }
  .list-none {
    list-style-type: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .content {
    max-width: 888px;
  }
  .textarea {
    background-color: var(--info100);
    border: none;
    width: 100%;
    outline: none;
    resize: none;
    background-image: -webkit-linear-gradient(
      transparent,
      transparent 39px,
      #000 39px,
      #000 40px,
      transparent 40px
    );
    background-image: -moz-linear-gradient(
      transparent,
      transparent 39px,
      #000 39px,
      #000 40px,
      transparent 40px
    );
    background-image: -ms-linear-gradient(
      transparent,
      transparent 39px,
      #000 39px,
      #000 40px,
      transparent 40px
    );
    background-image: -o-linear-gradient(
      transparent,
      transparent 39px,
      #000 39px,
      #000 40px,
      transparent 40px
    );
    background-image: linear-gradient(
      transparent,
      transparent 39px,
      #000 39px,
      #000 40px,
      transparent 40px
    );
    background-size: 100% 40px;
    line-height: 40px;
  }
  .input-sizer {
    display: inline-grid;
    align-items: center;
    background-color: white;
    position: relative;
    border-bottom: solid 1px var(--neutral200);
    // border-radius: 4px;
    padding-right: 2px;
    padding-left: 2px;
    // margin: 5px;
    // font-size: 10px;

    &.stacked {
      // padding: 0.5em;
      align-items: stretch;

      &::after,
      input,
      textarea {
        grid-area: 2 / 1;
      }
    }

    &::after,
    input,
    textarea {
      width: auto;
      min-width: 100px;
      grid-area: 1 / 2;
      font: inherit;
      // padding: 0.25em;
      margin: 0;
      resize: none;
      background: none;
      appearance: none;
      border: none;
    }

    span {
      // padding: 0.25em;
    }

    &::after {
      content: attr(data-value) " ";
      visibility: hidden;
      white-space: pre-wrap;
    }

    &:focus-within {
      border-bottom: solid 1px var(--primary500);

      textarea:focus,
      input:focus {
        outline: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .sub-topic-detatil-show {
    .icon {
      font-size: 20px;
    }
  }
}
@media (max-width: 480px) {
  .sub-topic-detatil-show {
    .icon {
      font-size: 16px;
    }
  }
}
