@import "../../../../../assets/scss/utilities/margin_padding";
@import "../../../../../assets/scss/utilities/border_radius";

.assessment-preview-nav {
  padding: 24px 48px 16px 48px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease-in-out;
  .w-full {
    width: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .btn-number {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    background-color: var(--secondary300);
    color: var(--neutral300);
    &.active {
      background-color: var(--neutral500);
      color: white;
    }
  }

  .numbering-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .numbering-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .numbering-exit {
    opacity: 1;
  }
  .numbering-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}

.assessment-preview {
  @include marginPaddingGenerator(100, ".");
  @include borderRadiusGenerator(".");

  min-height: 90vh;
  padding-left: 48px;
  padding-right: 48px;
  max-width: 888px;
  .preview-block {
    box-shadow: 0 0 15px rgb(226, 224, 224);
    padding:16px 0px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .assessment-preview {
    padding: 24px 48px;
  }
}
@media (max-width: 480px) {
  .assessment-preview {
    padding: 20px 24px;
    .btn-form {
      display: flex;
      button {
        width: 100%;
      }
    }
  }
}
