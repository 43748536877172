.btnUpload-group {
    position: relative;
    .btnUpload {
        cursor: pointer;
    }
    .upload-csv {
        display: none;
    }
}

// SIZE
.btnRegular {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
    &.btnIcon {
        .icon-button {
            font-size: 20px;
            @media (max-width: 480px) {
                font-size: 16px;
            }
        }
        padding: 4px;
    }
    @media (max-width: 768px) {
        &.btnTaSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 16px;
        }
        &.btnTaExSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
        }
    }
    @media (max-width: 480px) {
        &.btnMoSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 16px;
        }
        &.btnMoExSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
        }
    }
}
.btnSmall {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    &.btnIcon {
        .icon-button {
            font-size: 20px;
            @media (max-width: 480px) {
                font-size: 16px;
            }
        }
        padding: 4px;
    }
    @media (max-width: 768px) {
        &.btnTaExSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
        }
    }
    @media (max-width: 480px) {
        &.btnMoExSmall {
            font-family: 'Nunito', sans-serif;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 16px;
        }
    }
}
.btnExSmall {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px;
    &.btnIcon {
        .icon-button {
            font-size: 20px;
            @media (max-width: 480px) {
                font-size: 16px;
            }
        }
        padding: 4px;
    }
}

// COLORS
.btnPrimary {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--primary400);
    background-color: var(--primary400);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--primary300);
        background-color: var(--primary300);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--primary500);
        background-color: var(--primary500);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--primary400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnSuccess {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--success400);
    background-color: var(--success400);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--success300);
        background-color: var(--success300);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--success500);
        background-color: var(--success500);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--success400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnDanger {
    border-radius: 8px;
    text-decoration: none;
    color: white;
    border: 1px solid var(--danger400);
    background-color: var(--danger400);
    &:hover {
        color: white;
        border: 1px solid var(--danger300);
        background-color: var(--danger300);
    }
    &:active {
        box-shadow: none;
        color: white;
        border: 1px solid var(--danger500);
        background-color: var(--danger500);
    }
    &:focus {
        box-shadow: none !important;
        color: white;
        border: 1px solid var(--neutral500);
        background-color: var(--danger400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnSecondary {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--secondary400);
    background-color: var(--secondary400);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--secondary300);
        background-color: var(--secondary300);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--secondary500);
        background-color: var(--secondary500);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--secondary400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnWarning {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--warning400);
    background-color: var(--warning400);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--warning300);
        background-color: var(--warning300);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--warning500);
        background-color: var(--warning500);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--warning400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnInfo {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--info400);
    background-color: var(--info400);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--info300);
        background-color: var(--info300);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--info500);
        background-color: var(--info500);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--info400);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);
    }
}
.btnGhost {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid transparent;
    background-color: transparent;
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--primary100);
        background-color: var(--primary100);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid transparent;
        background-color: transparent;
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: transparent;
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnOutline {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid var(--neutral300);
    background-color: white;
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--neutral200);
        background-color: var(--primary100);
    }
    &:active {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: white;
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
        border: 1px solid var(--neutral500);
        background-color: var(--neutral100);;
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral300);
        border: 1px solid var(--neutral300);
        background-color: var(--neutral100);

    }
}
.btnWrapper {
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral400);
    border: 1px solid var(--neutral50);
    background-color: var(--neutral50);
    &:hover {
        color: var(--neutral500);
        border: 1px solid var(--secondary200);
        background-color: var(--secondary200);
    }
    &:active {
        color: var(--neutral500);
        border: 1px solid var(--secondary500);
        background-color: var(--secondary500);
    }
    &:focus {
        box-shadow: none;
        color: var(--neutral500);
        border: 1px solid var(--secondary500);
        background-color: var(--secondary200);
    }
    &.disabled {
        box-shadow: none;
        color: var(--neutral200);
        border: 1px solid var(--neutral100);
        background-color: var(--neutral100);

    }
}
.btnLink {
    padding: 0;
    border-radius: 8px;
    text-decoration: none;
    color: var(--neutral500);
    border: 1px solid transparent;
    background-color: transparent;
    &:hover {
        color: var(--neutral400);
    }
    &:focus {
        box-shadow: none !important;
        color: var(--neutral500);
    }
}

// SHADOW
.isShadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.btnBlock {
    width: 100%;
}
@media (max-width: 480px) {
    .btnMoBlock {
        width: 100%;
    }
}