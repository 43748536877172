:root {
  --white500: #e1e9f3;

  --neutral1000: #000000;
  --neutral900: #040608;
  --neutral800: #0b0e13;
  --neutral700: #151c26;
  --neutral600: #202a39;
  --neutral500: #2a384c;
  --neutral400: #556070;
  --neutral300: #7f8894;
  --neutral200: #aaafb7;
  --neutral100: #d4d7db;
  --neutral50: #fdfdfe;

  --primary500: #fdbf47;
  --primary400: #fdcc6c;
  --primary300: #fed991;
  --primary200: #fee5b5;
  --primary100: #fff9ed;

  --success600: #1bb184;
  --success500: #8ecfee;
  --success400: #a5d9f1;
  --success300: #bbe2f5;
  --success200: #d2ecf8;
  --success100: #f4fbfe;

  --danger500: #dc3545;
  --danger400: #e35d6a;
  --danger300: #ea868f;
  --danger200: #f1aeb5;
  --danger100: #fcebed;

  --warning500: #ff9900;
  --warning400: #ffad33;
  --warning300: #ffc266;
  --warning200: #ffd699;
  --warning100: #fff5e6;

  --info500: #8EEED1;
  --info400: #a5f1da;
  --info300: #bbf5e3;
  --info200: #d2f8ed;
  --info100: #f4fbfe;

  --secondary500: #e1e9f3;
  --secondary400: #e7edf5;
  --secondary300: #edf2f8;
  --secondary200: #f3f6fa;
  --secondary100: #f8fafc;
}
@import "./typography";
@import "./background";
@import "./border";
@import "./color";
@import "./radius";