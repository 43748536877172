.memberupload-info {
    padding: 20px 48px;
    min-height: 90vh;
    .nav-back {
        margin-top: 19px;
        margin-bottom: 28px;
    }  
}

@media (max-width: 768px) {
    .memberupload-info {
        padding: 24px 48px;
        .nav-back {
            margin-top: 0px;
            margin-bottom: 24px;
        }   
    }
}
@media (max-width: 480px) {
    .memberupload-info {
        padding: 20px 24px;
        .nav-back {
            margin-top: 0px;
            margin-bottom: 28px;
            a {
                font-size: 12px;
                line-height: 16px;
            }
            .iconback {
                font-size: 16px;
            }
        }   
    }
}